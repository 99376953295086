import React, {useEffect, useMemo, useState} from "react";
import * as Sentry from "@sentry/react";
import {IWeb3Facade} from "../facades/IWeb3Facade";
import {IConsolidationToolView} from "../Views/ConsolidationToolView";
import {
  AddressType,
  EstimateResultType,
  evmNetworks,
  IBalanceData,
  IBalanceTokenData,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IGeneralTxData,
  IMapValueByAddress,
  ITokenDict,
  ITransactionPriorityEnum,
  NetworkCurrencyEnum,
  NetworkTitleEnum,
  PrivateKeyType,
  ReceiverAddressType,
  StatusTxEnum
} from "../types";
import {CheckInfo, TransactionInfo} from "../../../components/Table";
import {IWeb3TokenFacade} from "../facades/IWeb3TokenFacade";
import {fromUnitToToken, GasHelper, partitionArrayIntoChunks, sendRequestDelay} from "../../../helpers";
import {RootState} from "../../../store";
import {generalTransferInfoActions} from "../../../store/common/generalTransferInfo.slice";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getSortedBalances} from "../../../helpers/sortData";
import {ETHFacade} from "../facades/ETH_Network/ETHFacade";
import {DISPERSE_FACADES} from "../../DisperseTool/factory";

function isWeb3TokenFacade(facadeByCurrency: IWeb3Facade | IWeb3TokenFacade): facadeByCurrency is IWeb3TokenFacade {
  return typeof facadeByCurrency.fetchTokenInfo === "function"
}

function withFacade(WrappedComponent: React.FC<IConsolidationToolView>, facadeByCurrency: IWeb3Facade | IWeb3TokenFacade) {
  const isDevEnv = process.env.REACT_APP_ENVIRONMENT === "dev";

  return () => {
    const networkCurrency = useMemo(() => {
      return NetworkCurrencyEnum[facadeByCurrency.network]
    }, [facadeByCurrency]);

    const isAllowDisperse = useMemo(() => {
      return DISPERSE_FACADES.hasOwnProperty(facadeByCurrency.network)
    }, [facadeByCurrency]);

    const [selectedCurrency, setSelectedCurrency] = useState<keyof ITokenDict | string>(networkCurrency)
    const [selectedCurrencyError, setSelectedCurrencyError] = useState<string | null>(null)
    const [selectedCurrencyIsLoading, setSelectedCurrencyIsLoading] = useState<boolean>(false)
    const [tokenDict, setTokenDict] = useState<ITokenDict>(facadeByCurrency.tokensDict)
    const [receiverAddress, setReceiverAddress] = useState<ReceiverAddressType | null>(null)
    const [privateKeysError, setPrivateKeysError] = useState<string | null>(null)
    const [privateKeysMessage, setPrivateKeysMessage] = useState<string | null>(null)
    const [estimateError, setEstimateError] = useState<string | null>(null)
    const [privateKeyByAddress, setPrivateKeyByAddress] = useState<IMapValueByAddress<PrivateKeyType>>(new Map())
    const [isValidatingPrivateKeys, setIsValidatingPrivateKeys] = useState<boolean>(false)
    const [transactionDataByAddress, setTransactionDataByAddress] = useState<IMapValueByAddress<IGeneralTxData>>(new Map())
    const [currentTransactionPriority, setCurrentTransactionPriority] = useState<keyof ITransactionPriorityEnum>(facadeByCurrency.defaultTransactionPriority)
    const [balanceDataByAddress, setBalanceDataByAddress] = useState<IBalanceData | IBalanceTokenData>({balanceByAddress: new Map()})
    const [feeDataByAddress, setFeeDataByAddress] = useState<IMapValueByAddress<BigInt>>(new Map())
    const [isLoadingEstimate, setIsLoadingEstimate] = useState<boolean>(false)

    const [txHashByAddress, setTxHashByAddress] = useState<IMapValueByAddress>(new Map())
    const [isProcessingSend, setIsProcessingSend] = useState<boolean>(false)
    const [isSuccessSend, setIsSuccessSend] = useState<true | undefined>()
    const [errorSend, setErrorSend] = useState<Error | undefined>()

    const dispatch = useAppDispatch()
    const previousNetwork = useAppSelector((state: RootState) => state.generalTransferInfo.network)
    const previousReceiverAddress = useAppSelector((state: RootState) => state.generalTransferInfo.receiverAddress)

    useEffect(() => {
      const currentNetwork = facadeByCurrency.network

      if (previousNetwork) {
        if (!evmNetworks.includes(previousNetwork) || !evmNetworks.includes(currentNetwork)) {
          dispatch(generalTransferInfoActions.resetPrivateKeys())
          dispatch(generalTransferInfoActions.resetReceiverAddress())
        }
      }
      if (previousReceiverAddress && evmNetworks.includes(currentNetwork)) {
        setReceiverAddress(previousReceiverAddress)
      }

      dispatch(generalTransferInfoActions.setPrivateKeysNetwork(currentNetwork))
    }, [])

    useEffect(() => {
      if (facadeByCurrency instanceof ETHFacade) {
        dispatch(generalTransferInfoActions.setReceiverAddress(receiverAddress || ''))
      }
    }, [receiverAddress])

    const tokenAddress = useMemo(() => {
      return tokenDict[selectedCurrency].address
    }, [selectedCurrency, tokenDict]);

    const toToken = useMemo(() => fromUnitToToken(tokenDict[selectedCurrency].decimal), [tokenDict, selectedCurrency])


    const receiverAddressError = useMemo(() => {
      if (!receiverAddress?.length || facadeByCurrency.validateAddress(receiverAddress)) return null

      return 'Address is invalid!'
    }, [receiverAddress]);


    const tableRows: TransactionInfo[] | CheckInfo [] = useMemo(() => {
      const resultTableData: CheckInfo | TransactionInfo[] = []

      privateKeyByAddress.forEach((privateKey, address) => {
        const {balanceByAddress, balanceTokenByAddress} = balanceDataByAddress

        resultTableData.push({
          status: txHashByAddress.has(address) ? StatusTxEnum.SUCCESS : StatusTxEnum.SKIP,
          address: address,
          privateKey: privateKey,
          balance: balanceByAddress.has(address) ? facadeByCurrency.toBaseCurrencyFromUnit(balanceByAddress.get(address)!) : "0",
          balanceToken: balanceTokenByAddress?.has(address) ? toToken(balanceTokenByAddress?.get(address)!) : "0",
          feeInBaseCurrency: feeDataByAddress.has(address) ? facadeByCurrency.toBaseCurrencyFromUnit(feeDataByAddress.get(address)!) : "0",
          txHash: txHashByAddress.get(address)
        })
      })
      return resultTableData
    }, [balanceDataByAddress, privateKeyByAddress, feeDataByAddress, txHashByAddress, toToken]);

    const recipientsAndValuesForCharge = useMemo(() => {
      const _recipientsAndValues: IMapValueByAddress<bigint> = new Map()

      if (selectedCurrency === networkCurrency || !isAllowDisperse) return _recipientsAndValues

      for (const address of feeDataByAddress.keys()) {
        const {balanceByAddress} = balanceDataByAddress
        const balance = balanceByAddress.get(address)
        const fee = feeDataByAddress.get(address)

        if (balance < fee) {
          _recipientsAndValues.set(address, (GasHelper.gasPricePlusPercent(fee, 20) - balance))
        }
      }
      return _recipientsAndValues
    }, [feeDataByAddress])

    const isDisabledEstimate = !!(!receiverAddress || receiverAddressError || privateKeysError || !privateKeyByAddress.size || privateKeyByAddress.size > facadeByCurrency.getLimitPrivateKeys(tokenAddress))
    const isDisabledCharge = !isAllowDisperse || isDisabledEstimate || estimateError || privateKeyByAddress.size > facadeByCurrency.getLimitPrivateKeys(tokenAddress)
    const isDisabledSend = isDisabledEstimate || recipientsAndValuesForCharge.size || estimateError || !(transactionDataByAddress.size > 0)


    async function toAddress(privateKeys: Set<PrivateKeyType>) {
      const _accountsAndKeys: IMapValueByAddress = new Map()
      setPrivateKeysError(null)
      setIsValidatingPrivateKeys(true)

      console.log('Parsing...')

      setTimeout(() => {
        privateKeys.forEach(function (key) {
          try {
            const {address, privateKey} = facadeByCurrency.privateKeyToAccount(key)
            _accountsAndKeys.set(address, privateKey)
          } catch (error: any) {
            Sentry.captureException(error, {
              tags: {
                section: "consolidation",
                method: "privateKeyToAccount"
              },
              contexts: {
                "__getOrCreateAssociatedTokenAccount": {
                  network: facadeByCurrency.network,
                  currency: NetworkCurrencyEnum[facadeByCurrency.network],
                  count_keys: privateKeys.size,
                }
              }
            });
            setPrivateKeysError(error?.message || `Private key ${key} is invalid`)
          }
        })
        setPrivateKeyByAddress(_accountsAndKeys)
        console.log('Parsed')
        setIsValidatingPrivateKeys(false)
        if (_accountsAndKeys.size > facadeByCurrency.getLimitPrivateKeys(tokenAddress)) {
          setPrivateKeysError(`You entered more private keys than specified in the limit. The limit is ${facadeByCurrency.getLimitPrivateKeys(tokenAddress)}.`)
          return
        }

        setPrivateKeysMessage("Converted successfully")
        setTimeout(() => setPrivateKeysMessage(null), 2000)

        if (!(facadeByCurrency instanceof ETHFacade)) {
          dispatch(generalTransferInfoActions.resetPrivateKeys())
        }
      }, 0)
    }


    function __fetchTokenData(addressInput: AddressType) {
      if (!facadeByCurrency.fetchTokenInfo) return

      setSelectedCurrencyIsLoading(true)
      facadeByCurrency.fetchTokenInfo(addressInput)
        .then(tokenData => {
          const _tokenDict = {...tokenDict}
          _tokenDict[tokenData.symbol] = {..._tokenDict[tokenData.symbol] ?? {}, ...tokenData}
          setTokenDict(_tokenDict)
          setSelectedCurrency(tokenData.symbol)
        })
        .catch(error => {
          Sentry.captureException(error, {
            tags: {
              section: "consolidation",
              method: "fetchTokenInfo"
            },
            contexts: {
              "__fetchTokenData": {
                network: facadeByCurrency.network,
                currency: NetworkCurrencyEnum[facadeByCurrency.network],
                count_keys: privateKeyByAddress.size,
                address_input: addressInput
              }
            }
          });
          console.error('__fetchTokenData.fetchTokenInfo=>', error?.message)
          setSelectedCurrencyError(error?.message)
        })
        .finally(() => {
          setSelectedCurrencyIsLoading(false)
        })
    }


    function handleSelectCurrency(currencyInput: keyof ITokenDict | string) {
      setSelectedCurrencyError(null)
      if (currencyInput !== networkCurrency) {
        if (tokenDict.hasOwnProperty(currencyInput)) {
          __fetchTokenData(tokenDict[currencyInput].address!)
        }
      } else {
        setSelectedCurrency(currencyInput)
      }
    }


    function handleAddCurrency(addressInput: AddressType) {
      if (!facadeByCurrency.validateAddress(addressInput)) {
        setSelectedCurrencyError('Address is invalid!')
        return
      }
      setSelectedCurrencyError(null)
      __fetchTokenData(addressInput)
    }


    async function _fetchBalance(): Promise<IBalanceData | IBalanceTokenData> {
      let _balanceDataByAddress: IBalanceData | IBalanceTokenData = {
        balanceByAddress: new Map()
      };
      const chunkSize: number = facadeByCurrency.getAddressesChunkSize(tokenAddress);
      const promises: Promise<boolean>[] = [];
      let counter: number = 0;
      for (const chunk of partitionArrayIntoChunks(Array.from(privateKeyByAddress.keys()), chunkSize)) {

        const req = () => facadeByCurrency.fetchBalanceDataByAddress(new Set(chunk), tokenAddress)
          .then(function (result) {
            const {balanceByAddress, balanceTokenByAddress} = _balanceDataByAddress

            _balanceDataByAddress = {
              balanceByAddress: new Map([...balanceByAddress, ...result.balanceByAddress.entries()]),
              balanceTokenByAddress: result.balanceTokenByAddress ?
                new Map([...(balanceTokenByAddress ?? []), ...(result.balanceTokenByAddress ?? [])])
                : undefined
            }
            return true;
          })
          .catch(error => {
            Sentry.captureException(error, {
                tags: {
                  section: "consolidation",
                  method: "fetchBalanceDataByAddress"
                },
                contexts: {
                  "_fetchBalance": {
                    network: facadeByCurrency.network,
                    currency: NetworkCurrencyEnum[facadeByCurrency.network],
                    count_keys: privateKeyByAddress.size,
                    chunk_size: chunkSize,
                    token_address: tokenAddress
                  }
                }
              },
            );
            setIsLoadingEstimate(false)
            setPrivateKeysError(error?.message)
            console.error(`${facadeByCurrency.constructor.name} -> fetchBalanceDataByAddress=>`, error)
            return false
          })
        promises.push(isDevEnv ?
          sendRequestDelay(req, 200 * counter)
          : req()
        )
        counter++;
      }

      await Promise.all(promises);

      let _sortedBalances: IMapValueByAddress<bigint>
      let _sortedPrivateKeys: IMapValueByAddress = new Map()
      if (_balanceDataByAddress.balanceTokenByAddress?.size) {
        _sortedBalances = getSortedBalances(_balanceDataByAddress.balanceTokenByAddress)
      } else {
        _sortedBalances = getSortedBalances(_balanceDataByAddress.balanceByAddress)
      }
      _sortedBalances.forEach((value, address) => _sortedPrivateKeys.set(address, privateKeyByAddress.get(address)!))
      privateKeyByAddress.forEach((privateKey, address) => _sortedPrivateKeys.set(address, privateKey))
      setBalanceDataByAddress(_balanceDataByAddress)
      setPrivateKeyByAddress(_sortedPrivateKeys)
      return _balanceDataByAddress
    }


    async function handleEstimate() {
      if (isDisabledEstimate) return
      setErrorSend(undefined)
      setEstimateError(null)
      setIsLoadingEstimate(true)
      facadeByCurrency.resetGasPrice();

      const _balanceDataByAddress: IBalanceData | IBalanceTokenData = await _fetchBalance()
      /**
       * For case if smth wrong in facade, or developer made mistake did not return value
       */
      if (!_balanceDataByAddress) return

      const _estimateResult: EstimateResultType = {
        feeDataByAddress: new Map(),
        txDataByAddress: new Map()
      }

      const promises: Promise<boolean>[] = [];
      const chunkSize: number = facadeByCurrency.getAddressesChunkSize(tokenAddress);
      const chunks: IDataForGenerateTransactions[] = [];
      let currBalanceChunkMap: IBalanceData | IBalanceTokenData = {
        balanceByAddress: new Map(),
      };


      const {balanceTokenByAddress, balanceByAddress} = _balanceDataByAddress
      if (tokenAddress) {
        currBalanceChunkMap.balanceTokenByAddress = new Map();
      }

      balanceByAddress.forEach((value, key) => {
        if (currBalanceChunkMap.balanceByAddress.size === chunkSize) {
          chunks.push({
            balanceDataByAddress: currBalanceChunkMap,
            privateKeyByAddress,
            transactionPriority: currentTransactionPriority,
            receiverAddress,
          } as IDataForGenerateTransactions);
          currBalanceChunkMap = {
            balanceByAddress: new Map(),
            balanceTokenByAddress: balanceTokenByAddress ? new Map() : undefined
          };
          currBalanceChunkMap.balanceByAddress.set(key, value);
          if (balanceTokenByAddress?.has(key)) {
            currBalanceChunkMap.balanceTokenByAddress?.set(key, balanceTokenByAddress?.get(key));
          }
        } else {
          currBalanceChunkMap.balanceByAddress.set(key, value);
          if (balanceTokenByAddress?.has(key)) {
            currBalanceChunkMap.balanceTokenByAddress?.set(key, balanceTokenByAddress?.get(key));
          }
        }
      });


      if (currBalanceChunkMap.balanceByAddress.size !== 0 || currBalanceChunkMap.balanceTokenByAddress?.size !== 0) {
        chunks.push({
          balanceDataByAddress: currBalanceChunkMap,
          privateKeyByAddress,
          transactionPriority: currentTransactionPriority,
          receiverAddress,
        } as IDataForGenerateTransactions);
      }

      for (const key in chunks) {
        const chunk = chunks[key];
        const generateTransactionsPromise = () => facadeByCurrency.generateTransactions(chunk, tokenAddress)
          .then((result) => {
            result.txDataByAddress.forEach((value, key) => {
              _estimateResult.txDataByAddress.set(key, value);
            });
            result.feeDataByAddress.forEach((value, key) => {
              _estimateResult.feeDataByAddress.set(key, value);
            });
            return true;
          })
          .catch(error => {
            Sentry.captureException(error, {
              tags: {
                section: "consolidation",
                method: "generateTransactions"
              },
              contexts: {
                "handleEstimate": {
                  network: facadeByCurrency.network,
                  currency: NetworkCurrencyEnum[facadeByCurrency.network],
                  count_keys: _balanceDataByAddress.balanceByAddress.size,
                  count_token_keys: _balanceDataByAddress.balanceTokenByAddress?.size || 0,
                  chunk_size: chunkSize,
                  token_address: tokenAddress
                }
              }
            });
            setIsLoadingEstimate(false)
            setEstimateError(error?.message)
            console.error(`${facadeByCurrency.constructor.name} -> generateTransactions=>`, error)
            return false
          });

        /**
         * Should wait for the first promise
         * Static variable with Gas Price sets inside it as we can ask Gas Price only once
         */
        if (key === '0') {
          await generateTransactionsPromise();
        } else {
          promises.push(
            isDevEnv ?
              sendRequestDelay(generateTransactionsPromise, 200 * key)
              : generateTransactionsPromise()
          );
        }
      }

      await Promise.all(promises);

      const {
        feeDataByAddress: _feeDataByAddress,
        txDataByAddress: _txDataByAddress
      } = _estimateResult
      setTransactionDataByAddress(_txDataByAddress)
      setFeeDataByAddress(_feeDataByAddress)
      setIsLoadingEstimate(false)
      facadeByCurrency.resetGasPrice();
    }


    async function handleSend() {
      if (isDisabledSend) return

      setIsProcessingSend(true)
      setErrorSend(undefined)
      let errorObj: Error | undefined;

      const resultTxReceiptByAddress: IMapValueByAddress = new Map()
      const promises: Promise<boolean>[] = [];
      const chunkSize: number = facadeByCurrency.getAddressesChunkSize(tokenAddress);
      const chunks: IDataForSendTransactions[] = [];
      let currTxDataChunkMap: IMapValueByAddress<IGeneralTxData> = new Map()
      let currBalanceChunkMap: IBalanceData = {
        balanceByAddress: new Map(),
      };

      const {balanceTokenByAddress, balanceByAddress} = balanceDataByAddress
      if (tokenAddress) {
        currBalanceChunkMap.balanceTokenByAddress = new Map()
      }
      balanceByAddress.forEach((balance, address) => {
        if (currBalanceChunkMap.balanceByAddress.size === chunkSize) {
          chunks.push({
            balanceDataByAddress: currBalanceChunkMap,
            privateKeyByAddress,
            transactionPriority: currentTransactionPriority,
            receiverAddress,
            transactionDataByAddress: currTxDataChunkMap
          } as IDataForSendTransactions);

          currBalanceChunkMap = {
            balanceByAddress: new Map(),
            balanceTokenByAddress: balanceTokenByAddress?.size ? new Map() : undefined
          };
          currTxDataChunkMap = new Map();

          if (transactionDataByAddress.has(address)) {
            currBalanceChunkMap.balanceByAddress.set(address, balance);
            if (balanceTokenByAddress?.has(address)) {
              currBalanceChunkMap.balanceTokenByAddress?.set(address, balanceTokenByAddress?.get(address));
            }
            currTxDataChunkMap.set(address, transactionDataByAddress.get(address))
          }
        } else {
          if (transactionDataByAddress.has(address)) {
            currBalanceChunkMap.balanceByAddress.set(address, balance);
            if (balanceTokenByAddress?.has(address)) {
              currBalanceChunkMap.balanceTokenByAddress?.set(address, balanceTokenByAddress?.get(address));
            }
            currTxDataChunkMap.set(address, transactionDataByAddress.get(address));
          }
        }
      });

      if (currBalanceChunkMap.balanceByAddress.size !== 0 || currBalanceChunkMap.balanceTokenByAddress?.size !== 0) {
        chunks.push({
          balanceDataByAddress: currBalanceChunkMap,
          privateKeyByAddress,
          transactionPriority: currentTransactionPriority,
          receiverAddress,
          transactionDataByAddress: currTxDataChunkMap
        } as IDataForSendTransactions);
      }

      for (const key in chunks) {
        const chunk = chunks[key];
        const sendTransactionsPromise = () => facadeByCurrency.sendTransactions(chunk, tokenAddress)
          .then((result) => {
            result.forEach((value, key) => {
              resultTxReceiptByAddress.set(key, value);
            });
            return true;
          })
          .catch(error => {
            Sentry.captureException(error, {
              tags: {
                section: "consolidation",
                method: "sendTransactions"
              },
              contexts: {
                "handleSend": {
                  network: facadeByCurrency.network,
                  currency: NetworkCurrencyEnum[facadeByCurrency.network],
                  count_keys: balanceByAddress.size,
                  count_token_keys: balanceTokenByAddress?.size || 0,
                  token_address: tokenAddress
                }
              }
            });
            errorObj = Error(error?.message || error)
            console.error(`${facadeByCurrency.constructor.name} -> sendTransactions=>`, error)
            setErrorSend(errorObj)
            setIsProcessingSend(false)
            return true
          });
        /**
         * Should wait for the first promise
         * Static variable with Gas Price sets inside it as we can ask Gas Price only once
         */
        if (key === '0') {
          await sendTransactionsPromise();
        }else {
          promises.push(
            isDevEnv ?
              sendRequestDelay(sendTransactionsPromise, 200 * key)
              : sendTransactionsPromise()
          );
        }
      }

      await Promise.all(promises);

      setTxHashByAddress(resultTxReceiptByAddress || (new Map()))
      setIsSuccessSend(true)
      setIsProcessingSend(false)

      _fetchBalance()

      setIsProcessingSend(false)
    }


    const props = {
      isWeb3TokenFacade: isWeb3TokenFacade(facadeByCurrency),
      networkCurrency: networkCurrency,
      network: facadeByCurrency.network,
      title: NetworkTitleEnum[facadeByCurrency.network],
      tableData: {
        tableRows: tableRows,
        linkForTxScan: facadeByCurrency.linkForTxScan,
      },
      transactionPriority: {
        value: currentTransactionPriority,
        setCurrentTransactionPriority,
        options: facadeByCurrency.transactionPriorityOptions
      },
      receiverAddress: {
        value: receiverAddress,
        setReceiverAddress,
        error: receiverAddressError
      },
      privateKeys: {
        isValidating: isValidatingPrivateKeys,
        error: privateKeysError,
        feedback: privateKeysError || estimateError || privateKeysMessage,
        limit: facadeByCurrency.getLimitPrivateKeys(tokenAddress),
        isLimit: privateKeyByAddress.size > facadeByCurrency.getLimitPrivateKeys(tokenAddress),
        privateKeyByAddress,
        privateKeysToAddresses: toAddress
      },
      estimate: {
        handleEstimate,
        isDisabled: isDisabledEstimate,
        isLoading: isLoadingEstimate,
        error: estimateError,
        balanceDataByAddress,
        feeDataByAddress,
      },
      charge: {
        recipientsAndValues: recipientsAndValuesForCharge,
        isDisabled: isDisabledCharge
      },
      send: {
        txHashByAddress,
        handleSend,
        isDisabled: isDisabledSend,
        isSuccess: isSuccessSend,
        isProcessing: isProcessingSend,
        error: errorSend ? errorSend.message : null,
      },
      selectedCurrency: {
        value: selectedCurrency,
        handleSelectCurrency,
        handleAddCurrency,
        error: selectedCurrencyError,
        isLoading: selectedCurrencyIsLoading,
        dataInfo: tokenDict[selectedCurrency] || null,
        tokensDict: tokenDict,
      },
    }

    return (
      <WrappedComponent {...props} />
    );

  }
}

export {withFacade}